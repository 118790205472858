import React from "react";
import { getDatabase, ref, push, set, get, DataSnapshot, child, remove } from "firebase/database";
import "./CSS/column.css";
import "./CSS/ProsCons.css"
// import "./Google Sign-In/auth_google_signin_redirect.js"
// import SignIn from "./Google Sign-In/auth_google_signin_redirect.js";
import {getAuth, setPersistence,
    browserLocalPersistence} from "firebase/auth";
// import { auth } from "firebase-admin";



// Import Admin SDK
// const { getDatabase } = require('firebase-admin/database');

// // Get a database reference to our blog
// const db = getDatabase();
// const ref = db.ref('server/saving-data/fireblog');


class Table extends React.Component {
    constructor(title, rowsPerPage=10, titleColor="000000") {
        super();
        const auth = getAuth();
        const columnData = [
        ];
        this.state = {
        auth: auth,
        user: "",
        title: title,
        columnData: columnData,
        currentPage: 0,
        rowsPerPage: rowsPerPage,
        input: "Table",
        nextId: columnData.length,
        titleColor: titleColor,
        };
        setPersistence(auth, browserLocalPersistence);
    }
    get title() {
        return this.state.title;
    }
    set title(newTitle) {
        this.setState({title: newTitle});
    }
    get currentPage() {
        return this.state.currentPage;
    }
    set currentPage(page) {
        this.setState({currentPage: page});
    }
    get rowsPerPage() {
        return this.state.rowsPerPage;
    }
    set rowsPerPage(rows) {
        this.setState({rowsPerPage: rows});
    }
    get numRows() {
        return this.state.columnData.length;
    }
    get numRowsOnPage() {
        return this.getNumRowsOnPage(this.currentPage)
    }
    getNumRowsOnPage = (page) => {
        return this.numPages-1 == page ? this.numRows - this.currentPage * page : this.rowsPerPage;
    }
    get numPages() {
        return Math.ceil(this.numRows / this.rowsPerPage);
    }
    getInputData = (evt) => {
        const inputValue = evt.target.value;
        this.setState({input: inputValue});
    }
    inputRowsPerPage = (evt) => {
        const inputValue = evt.target.value;
        this.setState({rowsPerPage: inputValue});
        this.forceUpdate();
    }
    turnPage = (pages) => {
        if(this.currentPage + pages >= 0 && this.currentPage + pages <= this.numPages - 1) {
            this.currentPage = this.currentPage + pages;
        }
    }
    visibleRows = () => {
        return this.state.columnData
        .map(
            (item) =>
            <div class="row" key={item.id}>
                <div class="column" key={item.id+"data"} ref={(column) => {if(column) {column.style.setProperty("color", "white", "important");}}}>
                    <li key={item.data}>{item.data}</li>
                </div>
                <div class="column" key={item.id+"remove"}>
                    <button onClick={() => this.remove(item.data)}>
                        Remove
                    </button>
                </div>
            </div>
            )
        .slice(
            this.currentPage*this.rowsPerPage,
            (this.currentPage + 1)*this.rowsPerPage);
    }
    remove(id) {
        if(this.state.columnData.length < 0) {
            this.prevPage();
        }
        this.setState({columnData: this.state.columnData.filter(item => item.data !== id)});
        if(this.state.auth.currentUser !== null) {
            const db = getDatabase();
            const Ref = ref(db, 'users/' + this.state.auth.currentUser.uid + '/' + this.title);
            get(Ref).then((snapshot) => {
                if (snapshot.exists()) {
                    // console.log(snapshot.val());
                    var found = false;
                    snapshot.forEach((data) => {
                        if(!found && data.key === id) {
                            remove(data.key);
                            found = true;
                        }
                    });
                    console.log(snapshot.val());
                    // snapshot.delete();
                    
                } else {
                    console.log("No data available");
                }
                }).catch((error) => {
                    console.error(error);
                });
        }
    }
    nextPage = () => {
        this.turnPage(1);
    }
    prevPage = () => {
        this.turnPage(-1);
    }
    addItem = () => {
        this.setState({columnData: this.state.columnData.concat({
            id: this.title.concat(this.state.nextId),
            data: this.state.input
        })})
        this.setState({auth: getAuth()});
        if(this.state.auth.currentUser !== null) {
            console.log("user id: " + this.state.auth.currentUser.uid);
            console.log("title: " + this.title);
            const db = getDatabase();
            // const ref = db.ref('users')
            push(ref(db, 'users/' + this.state.auth.currentUser.uid + '/' + this.title),
            {
                data: this.state.input
            });
            const Ref = ref(db, 'users/' + this.state.auth.currentUser.uid + '/' + this.title);
            // const snapshot = Ref.DataSnapshot
            // if (!snapshot.exists) {
            //     console.log('No matching documents.');
            //     return;
            // }
            // else {
                // console.log('Document data: ', snapshot.val());
            // }

            // const dbRef = ref(getDatabase());
            get(Ref).then((snapshot) => {
            if (snapshot.exists()) {
                console.log(snapshot.val());
                // snapshot.delete();
            } else {
                console.log("No data available");
            }
            }).catch((error) => {
                console.error(error);
            });

            // Ref.once('value')
            //     .then(function(snapshot) {
            //         var key = snapshot.key; // "ada"
            //         var childKey = snapshot.child("name/last").key; // "last"
            //         console.log(String(key));
            //         console.log(String(childKey));
            //     });

            // const snapshot = DataSnapshot;
            // Ref.once('value')
            //     .then(function(snapshot) {
            //         var key = snapshot.key; // "ada"
            //         var childKey = snapshot.child("name/last").key; // "last"
            //         console.log(String(key));
            //         console.log(String(childKey));
            //     });

            // snapshot.forEach(doc => {
            // console.log(doc.id, '=>', doc.data());
            // });index.js

            // var leadsRef = ref(db, 'users/' + this.state.auth.currentUser.uid + '/' + this.title);
            // leadsRef.get('value', function(snapshot) {
            //     snapshot.forEach(function(childSnapshot) {
            //         // var childData = childSnapshot.val();
            //         // this.setState({columnData: this.state.columnData.concat({
            //         //     id: this.title.concat(this.state.nextId),
            //         //     data: childData
            //         // })})

            //         console.log(childSnapshot.val());
            //     });
            // });
            
            // const usersRef = ref.child(this.state.auth.currentUser + "/" + this.title);
            // usersRef.push(this.columnData);


            // var fs = require('fs');

            // const fs = require('fs');
            // const os = require('os');
            // const path = require('path');
            // const tmpdir = os.tmpdir();
            // const filePath = path.join(tmpdir,'response.html');

            // // PROMISIFY WRITEFILE TO SAVE URL RESPONSE
            // const promisifiedWriteFile = function(text) {
            // return new Promise((resolve, reject) => {
            //     fs.writeFile(filePath, text, err => {
            //     if (err) {
            //         return reject(err);
            //     }
            //     return resolve();
            //     });
            // });
            // };
            // promisifiedWriteFile(this.columnData);

            // fs.writeFile(this.title + this.state.auth.currentUser.uid,
            //     this.columnData, function (err) {
            //     if (err) throw err;
            //     console.log('Replaced!');
            //   });
        }
        // const dbRef = getDatabase();
        // dbRef.push(this.title.concat(this.state.nextId)).child("description").setValue(this.state.input);
    
        // adapter.notifyDataSetChanged();

        this.setState({nextId: this.state.nextId + 1});
    }
    render() {
        return (
        <div>
            <h1>{this.title}</h1>
            <br/>
            <button onClick={this.addItem}>Add</button>
            <input type="text" onChange={this.getInputData}></input>
            <button onClick={this.prevPage}>Previous</button>
            <button onClick={this.nextPage}>Next</button>
            <br/>
            {this.visibleRows()}
            <br/>
            <button onClick={this.addItem}>Add</button>
            <input type="text" onChange={this.getInputData}></input>
            <button onClick={this.prevPage}>Previous</button>
            <button onClick={this.nextPage}>Next</button>
            <br/>
            <label ref={(label) => {if(label) {label.style.setProperty("color", "white", "important");}}}>
                {this.title} per page
                </label>
            <input
                type="number"
                min={1}
                defaultValue={this.numRowsOnPage}
                onChange={this.inputRowsPerPage}
            ></input>
            <br/>
            <h3 ref={(h3) => {if(h3) {h3.style.setProperty("color", "white", "important");}}}>
                Current Page: {this.state.currentPage}
            </h3>
        </div>
        );
    }
}

export {Table as default, Table}
