// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8ysn1T9_BCZ9PbMOev13GpOU0nzQXAl4",
  authDomain: "ydecision-10eea.firebaseapp.com",
  databaseURL: "https://ydecision-10eea-default-rtdb.firebaseio.com",
  projectId: "ydecision-10eea",
  storageBucket: "ydecision-10eea.appspot.com",
  messagingSenderId: "549681082441",
  appId: "1:549681082441:web:5566a7cc2c6e4aa41a8f98",
  measurementId: "G-CGKD87QTVB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);