import React from "react";
import Table from "./Table.js"
import logo from "./_images/yDecisionLogo.png"
import storage from "./firebase"
import "./CSS/App.css";
import "./CSS/column.css"
import "./CSS/textAlign.css"
import "./CSS/ProsCons.css"
import SignIn from "./Google Sign-In/auth_google_signin_redirect";


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo"/>
      </header>
      <div>
        <SignIn/>
      </div>
      <div className="column">
        <div className="App">
          <header className="App-header">
            <div className="pros">
              <Pros/>
            </div>
          </header>
        </div>
      </div>
      <div className="column">
        <div className="App">
          <header className="App-header">
            <div className="cons">
              <Cons/>
            </div>
          </header>
        </div>
      </div>
    </div>
  );
}

class Pros extends Table {
  constructor({title="Pros", rowsPerPage=10}) {
    super();
    const columnData = [
    ];
    this.state = {
      title: title,
      columnData: columnData,
      currentPage: 0,
      rowsPerPage: rowsPerPage,
      input: "",
      nextId: columnData.length,
    };
  }
}

class Cons extends Table {
  constructor({title="Cons", rowsPerPage=10}) {
    super();
    const columnData = [
    ];
    this.state = {
      title: title,
      columnData: columnData,
      currentPage: 0,
      rowsPerPage: rowsPerPage,
      input: "",
      nextId: columnData.length,
    };
  }
}

export default App;