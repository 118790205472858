import React from "react";
import {GoogleAuthProvider, getAuth, signInWithRedirect,
    getRedirectResult, signOut, setPersistence,
    browserLocalPersistence, onAuthStateChanged} from "firebase/auth";
import signinimage from "../_images/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png"


class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        setPersistence(auth, browserLocalPersistence);
        this.state = {
            provider: provider,
            auth: auth,
            credential: "",
            token: "",
            user: "",
            imageUrl: "",
            name: "",
            signedin: false,
        };
    }
    get provider() {
        return this.state.provider;
    }
    set provider(provider) {
        this.setState({provider: provider});
    }
    get auth() {
        return this.state.auth;
    }
    set auth(auth) {
        this.setState({auth: auth});
    }
    get credential() {
        return this.state.credential;
    }
    set credential(credential) {
        this.setState({credential: credential});
    }
    get token() {
        return this.state.token;
    }
    set token(token) {
        this.setState({token: token});
    }
    get user() {
        return this.state.user;
    }
    set user(user) {
        this.setState({user: user});
    }
    get imageUrl() {
        return this.state.imageUrl;
    }
    set imageUrl(imageUrl) {
        imageUrl = imageUrl || "";
        if(imageUrl == "" && this.state.credential != "") {
            imageUrl = this.state.auth.currentUser.getBasicProfile().getImageUrl();
        }
        this.setState({imageUrl: imageUrl});
    }
    get name() {
        this.name = this.name;
        return this.state.name;
    }
    set name(name) {
        name = name || "";
        if(name == "" && this.state.credential != "") {
            name = this.state.auth.currentUser.get().getBasicProfile().getName();
        }
        this.setState({name: name});
    }
    signinbutton = (evt) => {
        this.auth = getAuth();
        signInWithRedirect(this.auth, this.provider);
        this.setState({signedin: true})
        this.signinbuttonresult();
        this.forceUpdate();
    }
    signinbuttonresult = (evt) => {
        this.auth = this.getAuth();
        getRedirectResult(this.auth)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access Google APIs.
                this.credential = GoogleAuthProvider.credentialFromResult(result);
                this.token = this.credential.accessToken;
                // The signed-in user info.
                this.user = result.user;
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode + ": " + errorMessage);
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.error("Unable to verify " + credential + " for " + email);
            });
            this.setState({signedin: true});
            this.forceUpdate();
    }
    signoutbutton = () => {
        signOut(this.auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error occurred
        });
        this.credential = "";
        this.token = "";
        this.user = "";
        this.imageUrl = "";
        this.name = "";
        this.setState({signedin: false});
        this.forceUpdate();
    }
    onAuthStateChanged = ((user) => {
        this.setState({user});
        this.setState({signedin: true});
        this.forceUpdate();
    });
    onAuthStateChanged = ((user) => {
        this.setState({user});
        this.setState({signedin: true});
        this.forceUpdate();
    });
    OnClickSignInButton = (evt) => {
        return  (<button onClick={evt.onClick}>
            <img src={signinimage} alt="Sign in with Google"/>
            </button>);
    }
    OnClickSignOutButton = (evt) => {
        return (<button onClick={evt.onClick}>Logout</button>);
    }
    SignInAndOut = (evt) => {
        const signedin = evt.signedin;
        if(signedin) {
            return <this.OnClickSignOutButton/>
        }
        return <this.OnClickSignInButton/>
    }
    handleLoginClick() {
        this.setState({signedin: true});
        this.signinbutton();
    }
    handleLogoutClick() {
        this.setState({signedin: false});
        this.signoutbutton();
    }
    componentDidMount() {
        this.auth.onAuthStateChanged(user => {
            if(user) {
                this.setState({signedin: true});
            }
            else {
                this.setState({signedin: false});
            }
        });
    }
    render() {
        const signedin = this.state.signedin;
        let button;
        if (signedin) {
            button = <this.OnClickSignOutButton onClick={this.handleLogoutClick} />;
        } else {
            button = <this.OnClickSignInButton onClick={this.handleLoginClick} />;
        }
        return (
        <div>
            {button}
        </div>
        );
    }
}


export {SignIn as default, SignIn}